import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "base",
    redirect: () => {
      return { path: "/documents" };
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/BaseView.vue"),
    meta: {
      auth: true,
    },
    children: [
      {
        path: "documents",
        name: "documents",
        component: () =>
          import(
            /* webpackChunkName: "documents" */ "../views/DocumentView.vue"
          ),
        children: [
          {
            path: ":id",
            name: "document-detail",
            component: () =>
              import(
                /* webpackChunkName: "document-detail" */ "../views/DocumentDetailView.vue"
              ),
            children: [
              {
                path: "payment",
                name: "document-payment",
                component: () =>
                  import(
                    /* webpackChunkName: "document-payment" */ "../views/DocumentPaymentView.vue"
                  ),
              },
              {
                path: "payment/success",
                name: "payment-success",
                component: () =>
                  import(
                    /* webpackChunkName: "payment-success" */ "../views/PaymentSuccessView.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "profile",
        name: "user-profile",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../views/UserProfileView.vue"
          ),
        children: [
          {
            path: "edit",
            name: "user-profile-edit",
            component: () =>
              import(
                /* webpackChunkName: "user-profile-edit" */ "../views/UserProfileEditView.vue"
              ),
          },
        ],
      },
      {
        path: "reported",
        name: "reported",
        component: () =>
          import(
            /* webpackChunkName: "reported" */ "../views/ReportedView.vue"
          ),
        children: [
          {
            path: "new",
            name: "chat-bot",
            component: () =>
              import(
                /* webpackChunkName: "chat-bot" */ "../views/ChatBotView.vue"
              ),
          },
          {
            path: "new/success",
            name: "chat-bot-success",
            component: () =>
              import(
                /* webpackChunkName: "chat-bot-success" */ "../views/ChatBotSuccessView.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },
  {
    path: "/landing-page-v2",
    name: "landing-page-v2",
    component: () =>
      import(
        /* webpackChunkName: "landing-page" */ "../views/LandingPageView.vue"
      ),
  },
  {
    path: "/landing-page",
    name: "landing-page",
    component: () =>
      import(
        /* webpackChunkName: "landing-page" */ "../views/LandingPageViewV2.vue"
      ),
  },
  {
    path: "/gdpr",
    name: "gdpr",
    component: () =>
      import(/* webpackChunkName: "gdpr" */ "../views/GdprView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/prepare-logout",
    name: "prepare-logout",
    component: () =>
      import(
        /* webpackChunkName: "prepare-logout" */ "../views/PrepareLogoutView.vue"
      ),
  },
  {
    path: "/logout",
    name: "logout",
    component: () =>
      import(/* webpackChunkName: "logout" */ "../views/LogoutView.vue"),
  },
  {
    path: "/:pathNotFound(.*)*",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/ErrorView.vue"),
  },
  {
    path: "/keycloak",
    name: "keycloak",
    component: () =>
      import(/* webpackChunkName: "keycloak" */ "../views/KeycloakView.vue"),
  },
  {
    path: "/keycloak-success",
    name: "keycloak-success",
    component: () =>
      import(
        /* webpackChunkName: "keycloak-success" */ "../views/KeycloakSuccessView.vue"
      ),
  },
];

const router = createRouter({
  hashbang: false,
  history: createWebHistory(),
  routes,
});

export default (app) => {
  app.router = router;

  app.use(router);
};
