import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import http from "./http";
import auth from "./plugins/auth.js";
import { vue3Debounce } from "vue-debounce";
import VueSplide from "@splidejs/vue-splide";
import { createI18n } from "vue-i18n/dist/vue-i18n.cjs";
import en from "./translates/en.json";
import cs from "./translates/cs.json";

// TODO: Add language swapping handler
const i18n = createI18n({
  locale: "cs",
  messages: {
    en,
    cs,
  },
});

import "./index.css";

createApp(App)
  .directive("debounce", vue3Debounce({ lock: true }))
  .use(store)
  .use(router)
  .use(http)
  .use(auth)
  .use(i18n)
  .use(VueSplide)
  .mount("#app");
